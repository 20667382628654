import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Operator from './operator';
import Location from './location';
import MonitoringMap from './monitoringMap';
import SensorBox from './sensor-box';
import Sensor from './sensor';
import MeasurementUnit from './measurement-unit';
import Measurement from './measurement';
import AlertThreshold from './alert-threshold';
import Alert from './alert';
import SystemConfiguration from "./system-configuration";
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="system-configuration/*" element={<SystemConfiguration />} />
        <Route path="operator/*" element={<Operator />} />
        <Route path="location/*" element={<Location />} />
        <Route path="monitoring/*" element={<MonitoringMap />} />
        <Route path="sensor-box/*" element={<SensorBox />} />
        <Route path="sensor/*" element={<Sensor />} />
        <Route path="measurement-unit/*" element={<MeasurementUnit />} />
        <Route path="measurement/*" element={<Measurement />} />
        <Route path="alert-threshold/*" element={<AlertThreshold />} />
        <Route path="alert/*" element={<Alert />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
