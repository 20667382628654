import dayjs from 'dayjs';

export interface IMeasurementItemView {
  id?: number;
  sensorId?: number;
  sensorCode?: string;
  sensorName?: string;
  sensorType?: string;
  sensorBoxId?: number;
  sensorBoxName?: string;
  lastCommunicationDate?: string | null;
  lastCommunicationValue?: string | null;
  measurementUnitId?: number;
  measurementUnitCode?: string;
  locationId?: number;
  locationName?: string;
  operatorId?: number;
  operatorName?: string;
  status?: string;
}

export const defaultValue: Readonly<IMeasurementItemView> = {};
