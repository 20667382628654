import React from 'react';
import { Route } from 'react-router-dom';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import MonitoringMap from "app/entities/monitoringMap/monitoringMap";
const MonitoringMapRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<MonitoringMap />} />
  </ErrorBoundaryRoutes>
);
export default MonitoringMapRoutes;
