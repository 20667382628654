import './home.scss';

import React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { Row, Col, Alert } from 'reactstrap';

import { useAppSelector } from 'app/config/store';

export const Home = () => {
  const account = useAppSelector(state => state.authentication.account);

  return (
    <Row>
      <Col md="6" className="pad">
        <span className="hipster rounded" />
      </Col>
      <Col md="6">
        <h2>
          {account && account.login && <Translate contentKey="home.title" interpolate={{ username: ', ' + account.login }}>Welcome!</Translate>}
          {(!account || !account.login) && <Translate contentKey="home.title" interpolate={{ username: ''}}>Welcome!</Translate>}
        </h2>
        <p className="lead">
          <Translate contentKey="home.subtitle">This is your homepage</Translate>
        </p>
        {account?.login ? (
          <div>
            <Alert color="success">
              <Translate contentKey="home.logged.message" interpolate={{ username: account.login }}>
                You are logged in as user {account.login}.
              </Translate>
            </Alert>
          </div>
        ) : (
          <Row>
            <Col md="6">
              <Link to="/login" className="alert-link">
                <Translate contentKey="global.messages.info.authenticated.sign-in"> sign in</Translate>
              </Link>
            </Col>
            <Col md="6">
              <Link to="/account/register" className="alert-link">
                <Translate contentKey="global.messages.info.register.link">Register a new account</Translate>
              </Link>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default Home;
