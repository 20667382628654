import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'app/config/store';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getSensorsBySensorBoxId } from "app/entities/sensor/sensor.reducer";

export const SensorBoxDetails = (sensorBoxId) => {
  const dispatch = useAppDispatch();
  const [state, setState] = useState({ data: null });
  const [sensors, setSensors] = useState([]);
  useEffect(() => {
    setSensors([]);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    dispatch(getSensorsBySensorBoxId(sensorBoxId.sensorBoxId)).then(r => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setSensors(r.payload.data);
    });
  }, [sensorBoxId]);

  return (
    <div>
      Sensors:
      <ul className={'textDecoration: none'}>
        {sensors.map(s => (
          <div key={s.id}>
            <FontAwesomeIcon icon="circle" className="me-2" style={{ color: s.color }}></FontAwesomeIcon>
            {s.name} (<Translate contentKey={'sensorDataApp.SensorType.' + s.sensorType} />)
            {s.lastMeasurementValue != null
              ? ':' + s.lastMeasurementValue + ' ' + s.measurementUnit.code + '@' + s.lastMeasurementDate
              : ''}
          </div>
        ))}
      </ul>
    </div>
  );
};
export default SensorBoxDetails;
