import {createSlice} from "@reduxjs/toolkit";
import {EntityState} from "app/shared/reducers/reducer.utils";
import {defaultValue, IMonitoringMap} from "app/shared/model/monitoring-map.model";

const initialState:EntityState<IMonitoringMap> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,};
export type MonitoringState = Readonly<typeof initialState>;
export const MonitoringSlice = createSlice({
  name: 'monitoring',
  initialState: initialState as MonitoringState,
  reducers: {
  }
});

// Reducer
export default MonitoringSlice.reducer;
