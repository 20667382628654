import {createSlice} from "@reduxjs/toolkit";

const initialState = {};
export type MonitoringState = Readonly<typeof initialState>;
export const MonitoringSlice = createSlice({
  name: 'monitoring',
  initialState: initialState as MonitoringState,
  reducers: {
  }
});

// Reducer
export default MonitoringSlice.reducer;
