import operator from 'app/entities/operator/operator.reducer';
import location from 'app/entities/location/location.reducer';
import monitoring from 'app/entities/monitoringMap/monitoringMap.reducer';
import sensorBox from 'app/entities/sensor-box/sensor-box.reducer';
import sensor from 'app/entities/sensor/sensor.reducer';
import measurementUnit from 'app/entities/measurement-unit/measurement-unit.reducer';
import measurement from 'app/entities/measurement/measurement.reducer';
import alertThreshold from 'app/entities/alert-threshold/alert-threshold.reducer';
import alert from 'app/entities/alert/alert.reducer';
import measurementItemView from 'app/entities/measurement-item-view/measurement-item-view.reducer';
import systemConfiguration from 'app/entities/system-configuration/system-configuration.reducer';
import measurementView from 'app/entities/measurement-view/measurement-view.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  operator,
  location,
  monitoring,
  sensorBox,
  sensor,
  measurementUnit,
  measurement,
  alertThreshold,
  alert,
  systemConfiguration,
  measurementView,
  measurementItemView,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
