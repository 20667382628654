import React, {useState, useEffect} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {Button, Row, Col} from 'reactstrap';
import {Translate, translate, ValidatedField, ValidatedForm} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {useAppDispatch, useAppSelector} from 'app/config/store';

import {getEntities as getOperators} from 'app/entities/operator/operator.reducer';
import {getEntity, updateEntity, createEntity, reset} from './location.reducer';
import {FeatureGroup, MapContainer, Polygon, TileLayer} from "react-leaflet";
import {EditControl} from "react-leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";

export const LocationUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const {id} = useParams<'id'>();
  const isNew = id === undefined;

  const operators = useAppSelector(state => state.operator.entities);
  const locationEntity = useAppSelector(state => state.location.entity);
  const loading = useAppSelector(state => state.location.loading);
  const updating = useAppSelector(state => state.location.updating);
  const updateSuccess = useAppSelector(state => state.location.updateSuccess);
  const [polygons, setPolygons] = useState([]);

  const handleClose = () => {
    navigate('/location' + location.search);
  };

  function createPolyFromPerimeter(perimeter: any) {
    const result = {id: 1, latlngs: []};
    perimeter[0].latlngs.forEach(l => result.latlngs.push(l));
    return result;
  }
  function createPolyFromDraw(perimeter: any) {
    const result = {id: 1, latlngs: []};
    perimeter.forEach(l => result.latlngs.push(l));
    return result;
  }

  useEffect(() => {
    setPolygons([]);
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id)).then((r) => {
        // @ts-ignore
        const perimeter = r.payload.data.perimeter;
        if (perimeter && perimeter.length) {
          setPolygons(polygons => [...polygons, createPolyFromPerimeter(perimeter)]);
        }
      });
    }

    dispatch(getOperators({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...locationEntity,
      ...values,
      operator: operators.find(it => it.id.toString() === values.operator.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
        ...locationEntity,
        operator: locationEntity?.operator?.id,
        perimeter: polygons
      };
  const onEdited = (layers) => {
    //polygons that are changed are stored in changedPolys array
    const changedPolys = Object.values(layers._layers).map((layer) => {
      // @ts-ignore
      const latlngs = layer._latlngs[0].map((latlng) => {
        return [latlng.lat, latlng.lng];
      });
      setPolygons(polygons => [...polygons, latlngs]);
      // @ts-ignore
      return {id: layer.options.id, latlngs: latlngs};
    });

    //update state of all the polygons. replace polygons with changedPolys
    changedPolys.forEach((changedPoly) => {
      setPolygons((polygons) => {
        return polygons.map((polygon) => {
          if (polygon.id === changedPoly.id) {
            return {id: changedPoly.id, latlngs: changedPoly.latlngs};
          }
          return polygon;
        });
      });
    });
  };

  const onCreated = (layer) => {
    if (layer && layer._latlngs)
      setPolygons(polygons => [...polygons, createPolyFromDraw(layer._latlngs)]);
  }

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="sensorDataApp.location.home.createOrEditLabel" data-cy="LocationCreateUpdateHeading">
            <Translate contentKey="sensorDataApp.location.home.createOrEditLabel">Create or edit a Location</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="4">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              <ValidatedField
                label={translate('sensorDataApp.location.name')}
                id="location-name"
                name="name"
                data-cy="name"
                type="text"
                validate={{
                  required: {value: true, message: translate('entity.validation.required')},
                }}
              />
              <ValidatedField
                id="location-operator"
                name="operator"
                data-cy="operator"
                label={translate('sensorDataApp.location.operator')}
                type="select"
              >
                <option value="" key="0"/>
                {operators
                  ? operators.map(otherEntity => (
                    <option value={otherEntity.id} key={otherEntity.id}>
                      {otherEntity.name}
                    </option>
                  ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/location" replace
                      color="info">
                <FontAwesomeIcon icon="arrow-left"/>
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit"
                      disabled={updating}>
                <FontAwesomeIcon icon="save"/>
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
        <Col md="8">

          <section className='map-component'>
            <div className='map'>
              <MapContainer center={[46, 25.5]} zoom={7} scrollWheelZoom={true}>
                <FeatureGroup>
                  <EditControl
                    position="topright"
                    onEdited={(e) => onEdited(e.layers)}
                    onCreated={(e) => onCreated(e.layer)}
                    draw={{
                      marker: false,
                      circlemarker: false,
                      polyline: false,
                      rectangle: false,
                      circle: false
                    }}
                  />

                  {polygons?.map((polygon) => {
                    return (
                      <Polygon
                        key={polygon.id}
                        positions={polygon.latlngs}
                        pathOptions={{color: "black"}}
                      />
                    );
                  })}
                </FeatureGroup>

                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
              </MapContainer>
            </div>
          </section>
        </Col>
      </Row>
    </div>
  );
};

export default LocationUpdate;
