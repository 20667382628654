import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getSensorBox } from './sensor-box.reducer';
import { getSensorsBySensorBoxId } from "app/entities/sensor/sensor.reducer";

export const SensorBoxDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getSensorsBySensorBoxId(id));
  }, []);

  const sensorBoxEntity = useAppSelector(state => state.sensorBox.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="sensorBoxDetailsHeading">
          <Translate contentKey="sensorDataApp.sensorBox.detail.title">SensorBox</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="inventoryNumber">
              <Translate contentKey="sensorDataApp.sensorBox.inventoryNumber">Inventory Number</Translate>
            </span>
          </dt>
          <dd>{sensorBoxEntity.inventoryNumber}</dd>
          <dt>
            <span id="ftpAccount">
              <Translate contentKey="sensorDataApp.sensorBox.ftpAccount">Ftp Account</Translate>
            </span>
          </dt>
          <dd>{sensorBoxEntity.ftpAccount}</dd>
          <dt>
            <span id="installationDate">
              <Translate contentKey="sensorDataApp.sensorBox.installationDate">Installation Date</Translate>
            </span>
          </dt>
          <dd>
            {sensorBoxEntity.installationDate ? (
              <TextFormat value={sensorBoxEntity.installationDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="lng">
              <Translate contentKey="sensorDataApp.sensorBox.lng">Lng</Translate>
            </span>
          </dt>
          <dd>{sensorBoxEntity.lng}</dd>
          <dt>
            <span id="lat">
              <Translate contentKey="sensorDataApp.sensorBox.lat">Lat</Translate>
            </span>
          </dt>
          <dd>{sensorBoxEntity.lat}</dd>
          <dt>
            <span id="telegramChatId">
              <Translate contentKey="sensorDataApp.sensorBox.telegram-chat-id">Telegram chat ID</Translate>
            </span>
          </dt>
          <dd>{sensorBoxEntity.telegramChatId}</dd>
          <dt>
            <Translate contentKey="sensorDataApp.sensorBox.operator">Operator</Translate>
          </dt>
          <dd>{sensorBoxEntity.operatorName ? sensorBoxEntity.operatorName : ''}</dd>
          <dt>
            <Translate contentKey="sensorDataApp.sensorBox.location">Location</Translate>
          </dt>
          <dd>{sensorBoxEntity.locationName ? sensorBoxEntity.locationName : ''}</dd>
        </dl>
        <Button tag={Link} to="/sensor-box" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/sensor-box/${sensorBoxEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default SensorBoxDetail;
