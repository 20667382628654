import React, {useEffect, useState} from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import {getEntity, getLocationsByOperator} from './operator.reducer';

export const OperatorDetail = () => {
  const dispatch = useAppDispatch();
  const [locations, setLocations] = useState([]);
  const [operatorLocations, setOperatorLocations] = useState([]);

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id)).then((r) => {
      // @ts-ignore
      dispatch(getLocationsByOperator(r.payload.data.id)).then(r1 => {
        // @ts-ignore
        setOperatorLocations(r1.payload.data)
      });
    });
  }, []);

  const operatorEntity = useAppSelector(state => state.operator.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="operatorDetailsHeading">
          <Translate contentKey="sensorDataApp.operator.detail.title">Operator</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="name">
              <Translate contentKey="sensorDataApp.operator.name">Name</Translate>
            </span>
          </dt>
          <dd>{operatorEntity.name}</dd>
          <dt>
            <span>
              <Translate contentKey="sensorDataApp.operator.locations">Locations</Translate>
            </span>
          </dt>
          {operatorLocations && operatorLocations.length ? <ul>
              {operatorLocations.map((el) => <li>{el.name}</li>)}
            </ul> :
            <Translate contentKey="sensorDataApp.operator.no-locations">No locations</Translate>
          }
        </dl>
        <Button tag={Link} to="/operator" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/operator/${operatorEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default OperatorDetail;
