import { IOperator } from 'app/shared/model/operator.model';

export interface ILocation {
  id?: number;
  name?: string;
  operator?: IOperator | null;
  operatorId?: number | null;
  perimeter?: any;
}

export const defaultValue: Readonly<ILocation> = {};
