import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ISensor, defaultValue } from 'app/shared/model/sensor.model';

const initialState: EntityState<ISensor> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/sensors';
const sensorsApiUrl = 'api/sensors/by-box-id';

// Actions

export const getEntities = createAsyncThunk('sensor/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<ISensor[]>(requestUrl);
});
export const postEntities = createAsyncThunk('sensor/fetch_entity_list_post',
  async (data: any) => {
    const requestUrl = `${apiUrl}-filtered${data.sort ? `?page=${data.page}&size=${data.size}&sort=${data.sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
    return axios.post<ISensor[]>( requestUrl, {sensorType: data.sensorType, code: data.code, name: data.name, sensorBox: {id: data.sensorBox?.id}, measurementUnit: {id: data.measurementUnit?.id}});
  });

export const getEntity = createAsyncThunk(
  'sensor/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ISensor>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);
export const getSensorsBySensorBoxId = createAsyncThunk(
  'sensors/fetch_entities_by_sensor_box_id',
  async (id: string | number) => {
    const requestUrl = `${sensorsApiUrl}/${id}`;
    return axios.get<ISensor[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'sensor/create_entity',
  async (entity: ISensor, thunkAPI) => {
    const result = await axios.post<ISensor>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'sensor/update_entity',
  async (entity: ISensor, thunkAPI) => {
    const result = await axios.put<ISensor>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'sensor/partial_update_entity',
  async (entity: ISensor, thunkAPI) => {
    const result = await axios.patch<ISensor>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'sensor/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<ISensor>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const SensorSlice = createEntitySlice({
  name: 'sensor',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities, getSensorsBySensorBoxId), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(postEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity, postEntities, getSensorsBySensorBoxId), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = SensorSlice.actions;

// Reducer
export default SensorSlice.reducer;
