import { ISensor } from 'app/shared/model/sensor.model';
import { AlertThresholdType } from 'app/shared/model/enumerations/alert-threshold-type.model';
import { AlertThresholdLevel } from 'app/shared/model/enumerations/alert-threshold-level.model';

export interface IAlertThreshold {
  id?: number;
  alertThresholdType?: AlertThresholdType;
  alertThresholdLevel?: AlertThresholdLevel;
  code?: string;
  sensor?: ISensor | null;
  alertValue?: number;
}

export const defaultValue: Readonly<IAlertThreshold> = {};
