import { createAsyncThunk, isFulfilled, isPending } from "@reduxjs/toolkit";
import { createEntitySlice, EntityState } from "app/shared/reducers/reducer.utils";
import axios from "axios";
import {
  filterDefaultValue,
  IMeasurementFilterResponse
} from "app/entities/monitoringMap/measurement-filter-response.model";

const initialState:EntityState<IMeasurementFilterResponse> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: filterDefaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,};
export type MonitoringState = Readonly<typeof initialState>;
const apiUrl = 'api/measurements-graph';

export const postGraphItems = createAsyncThunk('graphItems/fetch_entity_list_post',
  async (data: any) => {
    const requestUrl = `${apiUrl}`;
    return axios.post<IMeasurementFilterResponse>( requestUrl, {sensorId: data.sensorId, startDate: data.startDate, endDate: data.endDate});
  });

export const MonitoringSlice = createEntitySlice({
  name: 'monitoring',
  initialState,
  extraReducers(builder) {
    builder.addMatcher(isFulfilled(postGraphItems), (state, action) => {
      const { data, headers } = action.payload;
      return {
        ...state,
        loading: false,
        entity: data,
        totalItems: parseInt(headers['x-total-count'], 10),
      };
    })
      .addMatcher(isPending(postGraphItems), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })

  }
});

// Reducer
export default MonitoringSlice.reducer;
