import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { updateEntity, createEntity, reset, getSensorBox } from "./sensor-box.reducer";
import { MapContainer, Marker, TileLayer, useMapEvents } from 'react-leaflet';
import L, { LatLng } from 'leaflet';
import { ILocation } from 'app/shared/model/location.model';
import { IOperator } from 'app/shared/model/operator.model';
import { ISensorBox } from 'app/shared/model/sensor-box.model';
import { getOperators } from "app/entities/operator/operator.reducer";
import { getLocations } from "app/entities/location/location.reducer";

export const SensorBoxUpdate = () => {
  const dispatch = useAppDispatch();
  const [selectedPosition, setSelectedPosition] = useState([]);
  const [positioning, setPositioning] = useState(false);
  const [zoom, setZoom] = useState(7);
  const [center, setCenter] = useState([46, 25.5]);
  const navigate = useNavigate();
  const [filteredLocations, setFilteredLocations] = useState<ILocation[]>([]);
  const { id } = useParams<'id'>();
  const isNew = id === undefined;
  const locations: ILocation[] = useAppSelector(state => state.location.entities);
  const operators: IOperator[] = useAppSelector(state => state.operator.entities);
  const sensorBox: ISensorBox = useAppSelector(state => state.sensorBox.entity);
  const loading = useAppSelector(state => state.sensorBox.loading);
  const updating = useAppSelector(state => state.sensorBox.updating);
  const updateSuccess = useAppSelector(state => state.sensorBox.updateSuccess);
  const markerRef = useRef(null);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setSelectedPosition(marker.getLatLng());
        }
      },
    }),
    []
  );
  const handleClose = () => {
    navigate('/sensor-box' + location.search);
  };

  useEffect(() => {
    dispatch(getOperators({}));
    dispatch(getLocations({}));
    if (isNew) {
      dispatch(reset);
    } else {
      dispatch(getSensorBox(id));
    }
    if(operators && locations && sensorBox && (isNew || sensorBox.id)) {
      if (sensorBox.lat && sensorBox.lng) {
        const lat = sensorBox.lat; const lng = sensorBox.lng;
        setSelectedPosition([lat, lng]);
        setCenter([lat, lng]);
        setZoom(15);
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const _op = sensorBox.operatorId;
      if (_op) {
        filterLocations(_op);
      }
    }
  }, [sensorBox.id, sensorBox.locationId, sensorBox.operatorId]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...sensorBox,
      ...values,
      operator: operators.find((it: IOperator): boolean => it.id === parseInt(values.operatorId, 10)),
      location: locations.find((it: ILocation): boolean => it.id === parseInt(values.locationId, 10)),
    };
    if (selectedPosition) {
      entity.lat = selectedPosition[0];
      entity.lng = selectedPosition[1];
    }
    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...sensorBox,
          operatorId: sensorBox?.operatorId,
          locationId: sensorBox?.locationId,
        };

  const Markers = () => {
    const map = useMapEvents({
      mouseup(e) {
        positioning ? setSelectedPosition([e.latlng.lat, e.latlng.lng]) : setSelectedPosition(selectedPosition);
      },
    });
    const icon = L.icon({
      iconUrl: '/content/images/marker-icon-blue.png',
      shadowUrl: '/content/images/markers-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
    });
    return selectedPosition && selectedPosition.length === 2 && selectedPosition[0] && selectedPosition[1] ? (
      <Marker
        key={selectedPosition[0]}
        position={new LatLng(selectedPosition[0], selectedPosition[1])}
        icon={icon}
        draggable={positioning}
        eventHandlers={eventHandlers}
        ref={markerRef}
      />
    ) : null;
  };

  const togglePositioning = useCallback(() => {
    setPositioning(p => !p);
  }, []);

  const filterLocations = (_operatorId: number) => {
    setFilteredLocations(locations.filter((it: ILocation): boolean => {
      return it.operatorId === _operatorId;
    }));
  };
  const changeOperator = e => {
    filterLocations(parseInt(e.target.value, 10));
  };
  if (loading) {
    return null;
  }
  return (
    <>
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="sensorDataApp.sensorBox.home.createOrEditLabel" data-cy="SensorBoxCreateUpdateHeading">
              <Translate contentKey="sensorDataApp.sensorBox.home.createOrEditLabel">Create or edit a SensorBox</Translate>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Button
                color="primary"
                id="save-entity"
                data-cy="entitypositioningButton"
                type="button"
                onClick={togglePositioning}
                className="btn btn-sm pl-3"
                title={selectedPosition[0] + ', ' + selectedPosition[1]}
              >
                <FontAwesomeIcon icon="map-location" />
                &nbsp;
                <Translate contentKey="sensorDataApp.sensorBox.positioning">Positioning</Translate>
              </Button>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="4">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <>
                <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
                  <ValidatedField
                    label={translate('sensorDataApp.sensorBox.inventoryNumber')}
                    id="sensor-box-inventoryNumber"
                    name="inventoryNumber"
                    data-cy="inventoryNumber"
                    type="text"
                    validate={{
                      required: { value: true, message: translate('entity.validation.required') },
                    }}
                  />
                  <ValidatedField
                    label={translate('sensorDataApp.sensorBox.ftpAccount')}
                    id="sensor-box-ftpAccount"
                    name="ftpAccount"
                    data-cy="ftpAccount"
                    type="text"
                    validate={{
                      required: { value: true, message: translate('entity.validation.required') },
                    }}
                  />
                  <ValidatedField
                    label={translate('sensorDataApp.sensorBox.telegram-chat-id')}
                    id="sensor-box-telegramChatId"
                    name="telegramChatId"
                    data-cy="telegramChatId"
                    type="text"
                    validate={{
                      required: { value: true, message: translate('entity.validation.required') },
                    }}
                  />
                  <ValidatedField
                    label={translate('sensorDataApp.sensorBox.installationDate')}
                    id="sensor-box-installationDate"
                    name="installationDate"
                    data-cy="installationDate"
                    type="date"
                  />
                  <ValidatedField
                    id="sensor-box-operatorId"
                    name="operatorId"
                    label={translate('sensorDataApp.sensorBox.operator')}
                    type="select"
                    onBlur={e => filterLocations(parseInt(e.target.value, 10))}
                  >
                    {!operators || operators.length !== 1 ? <option value={null} key="0" /> : null}
                    {operators.map((op: IOperator) => (
                      <option value={op.id} key={op.id}>
                        {op.name}
                      </option>
                    ))}
                  </ValidatedField>
                  <ValidatedField
                    id="sensor-box-locationId"
                    name="locationId"
                    label={translate('sensorDataApp.sensorBox.location')}
                    type="select"
                  >
                    {!filteredLocations || filteredLocations.length !== 1 ? (
                      <option value={null} key="-1">
                        ...
                      </option>
                    ) : null}
                    {filteredLocations
                      ? filteredLocations
                        .filter((e: ILocation): boolean => {
                          return e.operatorId === sensorBox.operatorId;
                        })
                        .map((l: ILocation, index: number) => (
                          <option value={l.id} key={index}>
                            {l.name}
                          </option>
                        ))
                      : ''}
                  </ValidatedField>
                  <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/sensor-box" replace color="info">
                    <FontAwesomeIcon icon="arrow-left" />
                    &nbsp;
                    <span className="d-none d-md-inline">
                      <Translate contentKey="entity.action.back">Back</Translate>
                    </span>
                  </Button>
                  &nbsp;
                  <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                    <FontAwesomeIcon icon="save" />
                    &nbsp;
                    <Translate contentKey="entity.action.save">Save</Translate>
                  </Button>
                </ValidatedForm>
              </>
            )}
          </Col>
          <Col md={8}>
            <section className="map-component">
              <div className="map">
                <MapContainer center={[center[0], center[1]]} zoom={zoom} scrollWheelZoom={true}>
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Markers />
                </MapContainer>
              </div>
            </section>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SensorBoxUpdate;
