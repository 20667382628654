import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './alert.reducer';

export const AlertDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const alertEntity = useAppSelector(state => state.alert.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="alertDetailsHeading">
          <Translate contentKey="sensorDataApp.alert.detail.title">Alert</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="measuredValue">
              <Translate contentKey="sensorDataApp.alert.measuredValue">Measured Value</Translate>
            </span>
          </dt>
          <dd>{alertEntity.measuredValue}</dd>
          <dt>
            <span id="measurementDate">
              <Translate contentKey="sensorDataApp.alert.measurementDate">Measurement Date</Translate>
            </span>
          </dt>
          <dd>
            {alertEntity.measurementDate ? (
              <TextFormat value={alertEntity.measurementDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="sensorDataApp.alert.sensor">Sensor</Translate>
          </dt>
          <dd>{alertEntity.sensor ? alertEntity.sensor.name : ''}</dd>
          <dt>
            <Translate contentKey="sensorDataApp.alert.alertThreshold">Alert Threshold</Translate>
          </dt>
          <dd>{alertEntity.alertThreshold ? alertEntity.alertThreshold.code : ''}</dd>
        </dl>
        <Button tag={Link} to="/alert" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/alert/${alertEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default AlertDetail;
