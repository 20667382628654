import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {Button, Row, Col} from 'reactstrap';
import {Translate} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT} from 'app/config/constants';
import {useAppDispatch, useAppSelector} from 'app/config/store';

import {getEntity} from './location.reducer';
import {FeatureGroup, MapContainer, Polygon, TileLayer} from "react-leaflet";
import {EditControl} from "react-leaflet-draw";

export const LocationDetail = () => {
  const dispatch = useAppDispatch();
  const [polygons, setPolygons] = useState([]);

  const {id} = useParams<'id'>();

  function createPolyFromPerimeter(perimeter: any) {
    const result = {id: 1, latlngs: []};
    perimeter[0].latlngs.forEach(l => result.latlngs.push(l));
    return result;
  }

  useEffect(() => {
    dispatch(getEntity(id)).then((r) => {
      // @ts-ignore
      const perimeter = r.payload.data.perimeter;
      if (perimeter && perimeter.length) {
        setPolygons(polygons => [...polygons, createPolyFromPerimeter(perimeter)]);
      }
    });
  }, []);

  const locationEntity = useAppSelector(state => state.location.entity);
  return (
    <>
      <Row>
        <Col md="4">
          <h2 data-cy="locationDetailsHeading">
            <Translate contentKey="sensorDataApp.location.detail.title">Location</Translate>
          </h2>
          <dl className="jh-entity-details">
            <dt>
            <span id="name">
              <Translate contentKey="sensorDataApp.location.name">Name</Translate>
            </span>
            </dt>
            <dd>{locationEntity.name}</dd>
            <dt>
              <Translate contentKey="sensorDataApp.location.operator">Operator</Translate>
            </dt>
            <dd>{locationEntity.operator ? locationEntity.operator.name : ''}</dd>
          </dl>
          <Button tag={Link} to="/location" replace color="info" data-cy="entityDetailsBackButton">
            <FontAwesomeIcon icon="arrow-left"/>{' '}
            <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/location/${locationEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt"/>{' '}
            <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
          </Button>
        </Col>
        <Col md="8">

          <section className='map-component'>
            <div className='map'>
              <MapContainer center={[46, 25.5]} zoom={7} scrollWheelZoom={true}>
                <FeatureGroup>
                  {polygons?.map((polygon) => {
                    return (
                      <Polygon
                        key={polygon.id}
                        positions={polygon.latlngs}
                        pathOptions={{color: "black"}}
                      />
                    );
                  })}
                </FeatureGroup>

                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
              </MapContainer>
            </div>
          </section>
        </Col>
      </Row>
    </>
  );
};

export default LocationDetail;
