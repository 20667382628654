import dayjs from 'dayjs';
import { ISensor } from 'app/shared/model/sensor.model';
import { IAlertThreshold } from 'app/shared/model/alert-threshold.model';

export interface IAlert {
  id?: number;
  measuredValue?: number;
  measurementDate?: any;
  sensor?: ISensor;
  alertThreshold?: IAlertThreshold;
}

export const defaultValue: Readonly<IAlert> = {};
