import React from 'react';
import { Translate } from 'react-jhipster';

import MenuItem from 'app/shared/layout/menus/menu-item';

const EntitiesMenu = () => {
  return (
    <>
      {/* prettier-ignore */}
      <MenuItem icon="asterisk" to="/system-configuration">
        <Translate contentKey="global.menu.entities.system-configuration" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/operator">
        <Translate contentKey="global.menu.entities.operator" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/location">
        <Translate contentKey="global.menu.entities.location" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/sensor-box">
        <Translate contentKey="global.menu.entities.sensorBox" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/sensor">
        <Translate contentKey="global.menu.entities.sensor" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/measurement-unit">
        <Translate contentKey="global.menu.entities.measurementUnit" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/measurement">
        <Translate contentKey="global.menu.entities.measurement" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/alert-threshold">
        <Translate contentKey="global.menu.entities.alertThreshold" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/alert">
        <Translate contentKey="global.menu.entities.alert" />
      </MenuItem>
      {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
    </>
  );
};

export default EntitiesMenu;
