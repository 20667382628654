import dayjs from 'dayjs';

export interface IMeasurementView {
  id?: number;
  lat?: number;
  lng?: number;
  status?: number;
  locationId?: number;
  locationName?: string;
  operatorId?: number;
  operatorName?: string;
  measurementDate?: any;
  measurementValue?: number | null;
  inventoryNumber?: string | null;
  ftpAccount?: string | null;
  installationDate?: any;
  color?: string;
}

export const defaultValue: Readonly<IMeasurementView> = {};
