import dayjs from 'dayjs';
import { ISensor } from 'app/shared/model/sensor.model';

export interface IMeasurement {
  id?: number;
  measurementDate?: string;
  measurementValue?: string;
  sensor?: ISensor;
}

export const defaultValue: Readonly<IMeasurement> = {};
