import React from 'react';
import { Translate } from 'react-jhipster';

import { NavItem, NavLink, NavbarBrand } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const BrandIcon = props => (
  <div {...props} className="brand-icon">
    <img src="../../../../content/images/logo.png" alt="Logo" />
  </div>
);

export const Brand = () => (
  <NavbarBrand tag={Link} to="/" className="brand-logo">
    <BrandIcon />
    <span className="brand-title">
      <Translate contentKey="global.title">SensorData</Translate>
    </span>
    <span className="navbar-version">{VERSION}</span>
  </NavbarBrand>
);

export const Home = () => (
  <NavItem>
    <NavLink tag={Link} to="/" className="d-flex align-items-center">
      <FontAwesomeIcon icon="home" />
      <span>
        <Translate contentKey="global.menu.home">Home</Translate>
      </span>
    </NavLink>
  </NavItem>
);

export const Monitoring = () => (
  <NavItem>
    <NavLink tag={Link} to="/monitoring" className="d-flex align-items-center">
      <FontAwesomeIcon icon="map-pin" />
      <span>
        <Translate contentKey="global.menu.monitoring">Monitoring</Translate>
      </span>
    </NavLink>
  </NavItem>
);
export const Boxes = () => (
  <NavItem>
    <NavLink tag={Link} to="/boxes" className="d-flex align-items-center">
      <FontAwesomeIcon icon="line-chart" />
      <span>
        <Translate contentKey="global.menu.boxes">Boxes</Translate>
      </span>
    </NavLink>
  </NavItem>
);
