import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ISensor } from 'app/shared/model/sensor.model';
import { getEntities as getSensors } from 'app/entities/sensor/sensor.reducer';
import { IAlertThreshold } from 'app/shared/model/alert-threshold.model';
import { getEntities as getAlertThresholds } from 'app/entities/alert-threshold/alert-threshold.reducer';
import { IAlert } from 'app/shared/model/alert.model';
import { getEntity, updateEntity, createEntity, reset } from './alert.reducer';

export const AlertUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const sensors = useAppSelector(state => state.sensor.entities);
  const alertThresholds = useAppSelector(state => state.alertThreshold.entities);
  const alertEntity = useAppSelector(state => state.alert.entity);
  const loading = useAppSelector(state => state.alert.loading);
  const updating = useAppSelector(state => state.alert.updating);
  const updateSuccess = useAppSelector(state => state.alert.updateSuccess);

  const handleClose = () => {
    navigate('/alert' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getSensors({}));
    dispatch(getAlertThresholds({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...alertEntity,
      ...values,
      sensor: sensors.find(it => it.id.toString() === values.sensor.toString()),
      alertThreshold: alertThresholds.find(it => it.id.toString() === values.alertThreshold.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...alertEntity,
          sensor: alertEntity?.sensor?.id,
          alertThreshold: alertEntity?.alertThreshold?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="sensorDataApp.alert.home.createOrEditLabel" data-cy="AlertCreateUpdateHeading">
            <Translate contentKey="sensorDataApp.alert.home.createOrEditLabel">Create or edit a Alert</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              <ValidatedField
                label={translate('sensorDataApp.alert.measuredValue')}
                id="alert-measuredValue"
                name="measuredValue"
                data-cy="measuredValue"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('sensorDataApp.alert.measurementDate')}
                id="alert-measurementDate"
                name="measurementDate"
                data-cy="measurementDate"
                type="date"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                id="alert-sensor"
                name="sensor"
                data-cy="sensor"
                label={translate('sensorDataApp.alert.sensor')}
                type="select"
                required
              >
                <option value="" key="0" />
                {sensors
                  ? sensors.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <FormText>
                <Translate contentKey="entity.validation.required">This field is required.</Translate>
              </FormText>
              <ValidatedField
                id="alert-alertThreshold"
                name="alertThreshold"
                data-cy="alertThreshold"
                label={translate('sensorDataApp.alert.alertThreshold')}
                type="select"
                required
              >
                <option value="" key="0" />
                {alertThresholds
                  ? alertThresholds.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.code}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <FormText>
                <Translate contentKey="entity.validation.required">This field is required.</Translate>
              </FormText>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/alert" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AlertUpdate;
