import axios from 'axios';
import {createAsyncThunk, isFulfilled, isPending} from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, EntityState, serializeAxiosError, createEntitySlice } from "app/shared/reducers/reducer.utils";
import { ISensorBox, defaultValue } from 'app/shared/model/sensor-box.model';

const initialState: EntityState<ISensorBox> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/sensor-boxes';

// Actions

export const getSensorBoxes = createAsyncThunk('operator/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<ISensorBox[]>(requestUrl);
});

export const getEntities = createAsyncThunk('sensorBox/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<ISensorBox[]>(requestUrl);
});
export const postEntities = createAsyncThunk('sensorBox/fetch_entity_list_post',
  async (data: any) => {
    const requestUrl = `${apiUrl}-filtered${data.sort ? `?page=${data.page}&size=${data.size}&sort=${data.sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
    return axios.post<ISensorBox[]>( requestUrl, {inventoryNumber: data.inventoryNumber, ftpAccount: data.ftpAccount, telegramChatId: data.telegramChatId, operatorId: data.operatorId, locationId: data.locationId});
  });

export const getSensorBox = createAsyncThunk(
  'sensorBox/fetch_sensor_box',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ISensorBox>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'sensorBox/create_entity',
  async (entity: ISensorBox, thunkAPI) => {
    const result = await axios.post<ISensorBox>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'sensorBox/update_entity',
  async (entity: ISensorBox, thunkAPI) => {
    const result = await axios.put<ISensorBox>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'sensorBox/partial_update_entity',
  async (entity: ISensorBox, thunkAPI) => {
    const result = await axios.patch<ISensorBox>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'sensorBox/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<ISensorBox>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice
export const SensorBoxSlice = createEntitySlice({
  name: 'sensorBox',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getSensorBox.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities, getSensorBoxes), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(postEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getSensorBox, postEntities, getSensorBoxes), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = SensorBoxSlice.actions;

// Reducer
export default SensorBoxSlice.reducer;
