import React, {useState, useEffect} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {Button, Table} from 'reactstrap';
import {Translate, getSortState, JhiPagination, JhiItemCount, ValidatedField, translate, log} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT} from 'app/config/constants';
import {ASC, DESC, ITEMS_PER_PAGE, SORT} from 'app/shared/util/pagination.constants';
import {overridePaginationStateWithQueryParams} from 'app/shared/util/entity-utils';
import {useAppDispatch, useAppSelector} from 'app/config/store';

import {getOperators, postEntities} from './location.reducer';

export const Location = () => {
  const [filter, setFilter] = useState({operatorId: null, name: ''});
  const [operators, setOperators] = useState([]);
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const locationList = useAppSelector(state => state.location.entities);
  const loading = useAppSelector(state => state.location.loading);
  const totalItems = useAppSelector(state => state.location.totalItems);

  const getAllEntities = () => {
    dispatch(
      postEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        ...filter
      })
    );
  };
  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    dispatch(getOperators({})).then(r => {
      // @ts-ignore
      setOperators(r.payload.data);
    })
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  return (
    <div>
      <h2 id="location-heading" data-cy="LocationHeading">
        <Translate contentKey="sensorDataApp.location.home.title">Locations</Translate>
      </h2>
      <div className="search-outer inline-flex">
        <form
          role="search"
          method="get"
          id="searchform"
          className="searchform"
          action=""
        >
          <div className="d-inline-block">
            <div>
                <Translate contentKey="sensorDataApp.location.name">Name</Translate>
            </div>
            <input
              className="form-control form-control-sm"
              type="search"
              name="name"
              id="name"
              onChange={(e) => setFilter({...filter, name: e.target.value})}
            />
          </div>
          <div className="d-inline-block ms-2">
            <div>
                <Translate contentKey="sensorDataApp.location.operator">Operator</Translate>
            </div>
            <select className="form-control form-control-sm"
              name="operatorId"
              onChange={(e) => setFilter({...filter, operatorId: e.target.value})}
            >
              <option value="-1">&nbsp;</option>
              {operators.map(op => (
                <option value={op.id} key={op.id}>
                  {op.name}
                </option>
              ))}
            </select>
          </div>
          <div className="d-inline-block ms-2">
              <Button className="me-2 btn-sm ms-2" color="info" onClick={handleSyncList} disabled={loading}>
                <FontAwesomeIcon icon="sync" spin={loading}/>{' '}
                <Translate contentKey="sensorDataApp.location.home.refreshListLabel">Refresh List</Translate>
              </Button>
          </div>
          <div className="d-inline-block ms-2 d-flex justify-content-end">
              <Link to="/location/new" className="btn btn-sm btn-primary jh-create-entity float-end"
                    id="jh-create-entity"
                    data-cy="entityCreateButton">
                <FontAwesomeIcon icon="plus"/>
                &nbsp;
                <Translate contentKey="sensorDataApp.location.home.createLabel">Create new Location</Translate>
              </Link>
          </div>
        </form>
      </div>
      <div className="table-responsive">
        {locationList && locationList.length > 0 ? (
          <Table responsive>
            <thead>
            <tr>
              <th className="hand" onClick={sort('name')}>
                <Translate contentKey="sensorDataApp.location.name">Name</Translate> <FontAwesomeIcon icon="sort"/>
              </th>
              <th>
                <Translate contentKey="sensorDataApp.location.operator">Operator</Translate> <FontAwesomeIcon
                icon="sort"/>
              </th>
              <th/>
            </tr>
            </thead>
            <tbody>
            {locationList.map((location, i) => (
              <tr key={`entity-${i}`} data-cy="entityTable">
                <td>{location.name}</td>
                <td>{location.operator ?
                  <Link to={`/operator/${location.operator.id}`}>{location.operator.name}</Link> : ''}</td>
                <td className="text-end">
                  <div className="flex-btn-group-container">
                    <Button tag={Link} to={`/location/${location.id}`} color="info" size="sm"
                            data-cy="entityDetailsButton" className="me-2">
                      <FontAwesomeIcon icon="eye"/>{' '}
                      <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                    </Button>
                    <Button
                      tag={Link}
                      to={`/location/${location.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                      color="primary"
                      size="sm"
                      data-cy="entityEditButton"
                      className="me-2"
                    >
                      <FontAwesomeIcon icon="pencil-alt"/>{' '}
                      <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                    </Button>
                    <Button
                      tag={Link}
                      to={`/location/${location.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                      color="danger"
                      size="sm"
                      data-cy="entityDeleteButton"
                    >
                      <FontAwesomeIcon icon="trash"/>{' '}
                      <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="sensorDataApp.location.home.notFound">No Locations found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={locationList && locationList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems}
                          itemsPerPage={paginationState.itemsPerPage} i18nEnabled/>
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Location;
