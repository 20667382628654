import {IOperator} from "app/shared/model/operator.model";
import {ILocation} from "app/shared/model/location.model";

export interface ISensorBox {
  id?: number;
  inventoryNumber?: string;
  ftpAccount?: string;
  installationDate?: string | null;
  lng?: number | null;
  lat?: number | null;
  operator?: IOperator | null;
  operatorId?: number | null;
  operatorName?: string | null;
  location?: ILocation | null;
  locationId?: number | null;
  locationName?: string | null;
  telegramChatId?: string | null;
}

export const defaultValue: Readonly<ISensorBox> = {};
