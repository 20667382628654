import { IMeasurementFilterResponseItem } from "app/entities/monitoringMap/measurement-filter-response-item.model";

export interface IMeasurementFilterResponse {
  sensorName?: string;
  items?: IMeasurementFilterResponseItem[];
}
export interface IMeasurementBoxFilterResponse {
  boxInventoryNumber?: string;
  items?: IMeasurementFilterResponseItem[];
}

export const filterDefaultValue: Readonly<IMeasurementFilterResponse> = {};
export const filterDefaultBoxValue: Readonly<IMeasurementBoxFilterResponse[]> = [];
