import { useAppDispatch, useAppSelector } from "app/config/store";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer, useMapEvents } from "react-leaflet";
import { getEntityByCode } from "app/entities/system-configuration/system-configuration.reducer";
import L from "leaflet";
import { SYSTEM_CONFIGURATION_CODES } from "app/config/system-configuration-code";
import { getMeasurementViews } from "app/entities/measurement-view/measurement-view.reducer";
import { TextFormat, translate, Translate, ValidatedField, ValidatedForm } from "react-jhipster";
import { APP_LOCAL_DATE_FORMAT, APP_LOCAL_DATETIME_FORMAT } from "app/config/constants";
import SensorBoxDetails from "app/entities/monitoringMap/sensorBox-details";
import { Button, Col, Row } from "reactstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Line } from "react-chartjs-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { postGraphItems } from "app/entities/monitoringMap/filteringMap.reducer";
import { getSensorsBySensorBoxId } from "app/entities/sensor/sensor.reducer";

export const MonitoringMap = () => {
  const dispatch = useAppDispatch();
  const sensorBoxList = useAppSelector(state => state.measurementView.entities);
  const [refreshRate, setRefreshRate] = useState(1);
  const [sensorBoxId, setSensorBoxId] = useState(-1);
  const location = useLocation();
  const navigate = useNavigate();
  const popupRef = useRef(null);
  // useEffect(() => {
  //   setSensors([]);
  //   if (sensorBoxId) {
  //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //     dispatch(getSensorsBySensorBoxId(sensorBoxId)).then(r => {
  //       // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //       // @ts-ignore
  //       setSensors(r.payload.data);
  //     });
  //   }
  // }, [sensorBoxId]);

  const getAllEntities = () => {
    dispatch(
      getMeasurementViews({
        page: 0,
        size: 100000
      })
    );
  };
  useEffect(() => {
    dispatch(getEntityByCode(SYSTEM_CONFIGURATION_CODES.REFRESH_TIME)).then((response) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const rr = response.payload.data.value;
      setRefreshRate(rr);
    });
  }, [refreshRate]);
  const ref = useRef(null);
  useEffect(() => {
    ref.current = setInterval(getAllEntities, refreshRate * 1000);
    return () => {
      if (ref.current) {
        clearInterval(ref.current);
      }
    };
  }, [sensorBoxList]);
  const iconOptions = {
    shadowUrl: "/content/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  };
  const iconBlue = L.icon({
    iconUrl: "/content/images/marker-icon-blue.png",
    shadowUrl: "/content/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });
  const iconGray = L.icon({
    iconUrl: "/content/images/marker-icon-gray.png",
    shadowUrl: "/content/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });
  const iconGreen = L.icon({
    iconUrl: "/content/images/marker-icon-green.png",
    shadowUrl: "/content/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });
  const iconRed = L.icon({
    iconUrl: "/content/images/marker-icon-red.png",
    shadowUrl: "/content/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });
  const iconYellow = L.icon({
    iconUrl: "/content/images/marker-icon-yellow.png",
    shadowUrl: "/content/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });
  const icon = L.icon({ iconUrl: "/content/images/marker-icon.png" });
  const SensorDetails = () => {
    return <div>SensorDetails</div>;
  };
  const eventHandlers = useMemo(
    () => ({
      remove() {
        setSensorBoxId(-1);
      }
    }),
    []
  );
  const markerEventHandlers = useMemo(
    () => ({
      click(e) {
        const key = e.target.options.children.key;
        if (sensorBoxId !== key) {
          setSensorBoxId(key);
          return;
        }
      }
    }),
    [sensorBoxId]
  );

  return (
    <Row>
      <Col md={6}>
        <section className="map-component">
          <div className="map">
            <MapContainer center={[46, 25.5]} zoom={7} scrollWheelZoom={true} key={'map'}>
              <TileLayer key={'tile-layer'}
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {sensorBoxList ? sensorBoxList.map((marker, i) => (
                <>
                  <Marker key={marker.id} position={[marker.lat, marker.lng]}
                          eventHandlers={markerEventHandlers}
                          icon={marker.color !== "red" ? iconGreen : iconRed}>
                    <Popup key={marker.id} minWidth={500} eventHandlers={eventHandlers}>
                      <div className="leaflet-control-attribution"><Translate
                        contentKey={"sensorDataApp.sensorBox.inventoryNumber"}>Inventory
                        Number</Translate>: {marker.inventoryNumber}</div>
                      <div className="leaflet-control-attribution"><Translate
                        contentKey={"sensorDataApp.sensorBox.ftpAccount"}>FTP Account</Translate>: {marker.ftpAccount}
                      </div>
                      <div className="leaflet-control-attribution"><Translate
                        contentKey={"sensorDataApp.sensorBox.installationDate"}>InstallationDate</Translate>: {marker.installationDate ? (
                        <TextFormat type="date" value={marker.installationDate} format={APP_LOCAL_DATE_FORMAT} />
                      ) : null}</div>
                      {sensorBoxId !== -1 ? <SensorBoxDetails sensorBoxId={marker.id} /> : ""}
                    </Popup>
                  </Marker>
                </>
              )) : (<p>Loading...</p>)}
            </MapContainer>
          </div>
        </section>
      </Col>
      <Col md={6}>{sensorBoxId !== -1 ? <Graphs sensorBoxIdForGraph={sensorBoxId} /> : ""}
      </Col>
    </Row>
  );
};
export default MonitoringMap;
export const Graphs = (sensorBoxIdForGraph) => {
  const dispatch = useAppDispatch();
  const [sensors, setSensors] = useState([]);
  const [filter, setFilter] = useState({ sensorId: null, startDate: null, endDate: null });
  const [graphItems, setGraphItems] = useState({sensorName: null, items: []});
  const [options, setOptions] = useState<{ responsive: true,
    plugins: {
      title: {
        display: boolean,
        text: string
      }
    }
  }>({ responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Grafice sensor"
      }
    }
  });
  const [data, setData] = useState<{ labels: string[]; datasets: [{ data: number[] }] }>({ labels: [], datasets: [{ data: [] }] });

  useEffect(() => {
    setSensors([]);
    if (sensorBoxIdForGraph && sensorBoxIdForGraph.sensorBoxIdForGraph) {
      dispatch(getSensorsBySensorBoxId(sensorBoxIdForGraph.sensorBoxIdForGraph)).then(r => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setSensors(r.payload.data);
      });
    }
  }, []);
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip
  );
  function filterSensors() {
    dispatch(postGraphItems(filter)).then(response => {
      // @ts-ignore
      const r = response.payload.data;
      const sn = r.sensorName;
      const responseItems = [];
      const responseLabels = [];
      const responseValues = [];
      r.items.forEach(i => {
        responseItems.push({ key: i.key, value: i.value });
        responseLabels.push(i.key);
        responseValues.push(i.value);
        setGraphItems({sensorName: sn, items: responseItems});
      });
      setOptions({...options, plugins: { title: { display: true, text: 'Grafice Sensor ' + sn } } });
      setData({labels: responseLabels, datasets: [{ data: responseValues }]});
    });
    return true;
  }

  return (
    <div className="search-outer inline-flex">
      <ValidatedForm onSubmit={filterSensors}>
        <div className="d-inline-block">
          <ValidatedField
            name="operatorId"
            label={translate("sensorDataApp.monitoring.sensor")}
            type="select"
            onChange={(e) => setFilter({ ...filter, sensorId: e.target.value })}
          >
            <option value={null} key="0" />
            {sensors.map(s => (
              <option value={s.id} key={s.id}>
                {s.name}&nbsp;({s.sensorType})
              </option>
            ))}
          </ValidatedField>
        </div>
        <div className="d-inline-block ms-2">
          <ValidatedField
            id="startDate"
            name="startDate"
            label={translate("sensorDataApp.monitoring.start-date")}
            data-cy="startDate"
            type="date"
            onChange={(e) => setFilter({ ...filter, startDate: e.target.value + 'T00:00:00Z' })}
          />
        </div>
        <div className="d-inline-block ms-2">
          <ValidatedField
            id="endDate"
            name="endDate"
            label={translate("sensorDataApp.monitoring.end-date")}
            type="date"
            data-cy="endDate"
            onChange={(e) => setFilter({ ...filter, endDate: e.target.value + 'T23:59:59Z' })}
          />
        </div>
        <div className="d-inline-block ms-2">
          {filter.sensorId ? <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit">
            <FontAwesomeIcon icon="search" />
            &nbsp;
            <Translate contentKey="entity.action.search">Save</Translate>
          </Button> : ''}
        </div>
      </ValidatedForm>
      {graphItems ? <Line options={options} data={data} /> : ''}
    </div>
  );
};
