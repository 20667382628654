import { ISensorBox } from 'app/shared/model/sensor-box.model';
import { IMeasurementUnit } from 'app/shared/model/measurement-unit.model';
import { SensorType } from 'app/shared/model/enumerations/sensor-type.model';

export interface ISensor {
  id?: number;
  sensorType?: SensorType;
  code?: string;
  name?: string;
  sensorBox?: ISensorBox;
  measurementUnit?: IMeasurementUnit;
  lastMeasurementDate?: any;
  lastMeasurementValue?: number;
  color?: string;
}

export const defaultValue: Readonly<ISensor> = {};
