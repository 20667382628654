import React, {useState, useEffect} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {Button, Table} from 'reactstrap';
import {Translate, TextFormat, getSortState, JhiPagination, JhiItemCount} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {APP_LOCAL_DATE_FORMAT} from 'app/config/constants';
import {ASC, DESC, ITEMS_PER_PAGE, SORT} from 'app/shared/util/pagination.constants';
import {overridePaginationStateWithQueryParams} from 'app/shared/util/entity-utils';
import {useAppDispatch, useAppSelector} from 'app/config/store';

import {getEntities} from './alert.reducer';
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import './alert.css';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

export const Alert = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );
  const [fullAlertList, setFullAlertList] = useState([]);
  const alertList = useAppSelector(state => state.alert.entities);
  const loading = useAppSelector(state => state.alert.loading);
  const totalItems = useAppSelector(state => state.alert.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };
  useEffect(() => {
    dispatch(getEntities({
      page: 0,
      size: 1000,
      sort: 'id,desc'
    })).then(r => {
      // @ts-ignore
      const list = r.payload.data;
      setFullAlertList(list);
    });
  }, []);
  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };
  const LeafIcon = L.DivIcon.extend({
    options: {}
  });
  const blueIcon = L.icon({
      iconUrl:
        "/content/images/pinBlue.png"
    }),
    greenIcon = L.icon({
      iconUrl: '/content/images/marker-icon-green.png',
      shadowUrl: '/content/images/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });
  return (
    <>
      <h2 id="alert-heading" data-cy="AlertHeading">
        <Translate contentKey="sensorDataApp.alert.home.title">Alerts</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading}/>{' '}
            <Translate contentKey="sensorDataApp.alert.home.refreshListLabel">Refresh List</Translate>
          </Button>
        </div>
      </h2>
      <Tabs>
        <TabList>
          <Tab>
            <Translate contentKey="sensorDataApp.alert.view-mode.tabelar">Tabelar</Translate>
          </Tab>
          <Tab>
            <Translate contentKey="sensorDataApp.alert.view-mode.geographical">Geographical</Translate>
          </Tab>
        </TabList>
        <TabPanel>
          <div>
            <div className="table-responsive">
              {alertList && alertList.length > 0 ? (
                <Table responsive>
                  <thead>
                  <tr>
                    <th className="hand" onClick={sort('measuredValue')}>
                      <Translate contentKey="sensorDataApp.alert.measuredValue">Measured Value</Translate>
                      <FontAwesomeIcon icon="sort"/>
                    </th>
                    <th className="hand" onClick={sort('measurementDate')}>
                      <Translate contentKey="sensorDataApp.alert.measurementDate">Measurement Date</Translate>
                      <FontAwesomeIcon icon="sort"/>
                    </th>
                    <th>
                      <Translate contentKey="sensorDataApp.alert.sensor">Sensor</Translate> <FontAwesomeIcon
                      icon="sort"/>
                    </th>
                    <th>
                      <Translate contentKey="sensorDataApp.alert.alertThreshold">Alert Threshold</Translate>
                      <FontAwesomeIcon icon="sort"/>
                    </th>
                    <th/>
                  </tr>
                  </thead>
                  <tbody>
                  {alertList.map((alert, i) => (
                    <tr key={`entity-${i}`} data-cy="entityTable">
                      <td>{alert.measuredValue}</td>
                      <td>
                        {alert.measurementDate ?
                          <TextFormat type="date" value={alert.measurementDate} format={APP_LOCAL_DATE_FORMAT}/> : null}
                      </td>
                      <td>{alert.sensor ? <Link to={`/sensor/${alert.sensor.id}`}>{alert.sensor.name}</Link> : ''}</td>
                      <td>
                        {alert.alertThreshold ? <Link
                          to={`/alert-threshold/${alert.alertThreshold.id}`}>{alert.alertThreshold.code}</Link> : ''}
                      </td>
                      <td className="text-end">
                        <div className="flex-btn-group-container">
                          <Button tag={Link} to={`/alert/${alert.id}`} color="info" size="sm"
                                  data-cy="entityDetailsButton" className="me-2">
                            <FontAwesomeIcon icon="eye"/>{' '}
                            <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </Table>
              ) : (
                !loading && (
                  <div className="alert alert-warning">
                    <Translate contentKey="sensorDataApp.alert.home.notFound">No Alerts found</Translate>
                  </div>
                )
              )}
            </div>
            {totalItems ? (
              <div className={alertList && alertList.length > 0 ? '' : 'd-none'}>
                <div className="justify-content-center d-flex">
                  <JhiItemCount page={paginationState.activePage} total={totalItems}
                                itemsPerPage={paginationState.itemsPerPage} i18nEnabled/>
                </div>
                <div className="justify-content-center d-flex">
                  <JhiPagination
                    activePage={paginationState.activePage}
                    onSelect={handlePagination}
                    maxButtons={5}
                    itemsPerPage={paginationState.itemsPerPage}
                    totalItems={totalItems}
                  />
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </TabPanel>
        <TabPanel>
          <section className='map-component'>
            <div className='map'>
              <MapContainer center={[45.8, 25.5]} zoom={7} scrollWheelZoom={true}>
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {fullAlertList ? fullAlertList.map((alertItem, i) => (
                  <Marker key={i}
                          position={[alertItem.sensor.sensorBox.lat, alertItem.sensor.sensorBox.lng]}
                          icon={greenIcon}>
                    <Popup>
                      A pretty CSS3 popup. <br/> Easily customizable.
                    </Popup>
                  </Marker>
                )) : (<p>Loading...</p>)}
              </MapContainer>
            </div>
          </section>
        </TabPanel>
      </Tabs>
    </>
  );
};

export default Alert;
